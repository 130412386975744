<template>
  <div class="main_container">
    <div class="main_page">
      <div class="page_title">{{$route.meta.title}}</div>
      <div class="page_containt">
        <div class="page_search">
          <el-form ref="searchForm" :model="searchForm">
            <el-row type="flex">
              <el-col class="search_col">
                <el-input
                  size="small"
                  type="text"
                  v-model="searchForm.key"
                  style="width: 150px"
                  placeholder="名称"
                />
              </el-col>
              <el-col class="search_col">
                <el-button
                  type="primary"
                  size="small"
                  native-type="submit"
                  @click.native.prevent="handleSearch"
                  >搜索</el-button
                >
              </el-col>
              <el-col class="search_col">
                <el-button size="small" @click="resetSearchForm()"
                  >重置</el-button
                >
              </el-col>
              <el-col class="btn_col">
                <el-button type="primary" size="small" @click="doForm()"
                  >新增管理员</el-button
                >
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="page_box" ref="pageBox">
          <el-table
            v-loading="loading"
            row-key="id"
            size="small"
            class="el_table"
            cell-class-name="el_table_cell"
            header-row-class-name="el_table_header"
            :data="listData"
            :height="tableHeight"
            style="width: 100%"
          >
            <el-table-column prop="username" label="用户名"> </el-table-column>
            <el-table-column prop="name" label="姓名"> </el-table-column>
             <el-table-column label="管理员类型">
              <template slot-scope="scope">
               {{scope.row.admin_type == 1?'平台管理员':'员工'}}
              </template>
            </el-table-column>
            <el-table-column label="权限组">
              <template slot-scope="scope">
                <div v-if="scope.row.is_admin == 1">超级管理员</div>
                <div v-else>{{ scope.row.group?scope.row.group.name:''}}</div>
              </template>
            </el-table-column>
            <el-table-column label="状态" width="100">
              <template slot-scope="scope">
                <el-select
                  popper-class="select"
                  @change="setStatus(scope.row)"
                  v-model="scope.row.status"
                  size="small"
                  v-if="scope.row.is_admin != 1 && scope.row.admin_type != 2"
                >
                  <el-option
                    v-for="item in statusOptions"
                    :key="item.status"
                    :label="item.name"
                    :value="item.status"
                  >
                  </el-option>
                </el-select>
                <div v-else>{{ scope.row.status == 1 ? "开通" : "禁用" }}</div>
              </template>
            </el-table-column>
            <el-table-column label="创建日期" prop="created_at" width="140">
            </el-table-column>
            <el-table-column
              fixed="right"
              align="right"
              header-align="right"
              label="操作"
              width="250"
            >
              <template slot-scope="scope">
                <el-button
                  class="btn"
                  type="text"
                  icon="el-icon-edit"
                  size="small"
                  v-if="scope.row.is_admin != 1"
                  @click="passwordItem(scope.row)"
                >
                  重置密码
                </el-button>
                <el-button
                  @click="editItem(scope.row)"
                  v-if="scope.row.is_admin != 1 && scope.row.admin_type != 2"
                  class="btn"
                  type="text"
                  icon="el-icon-edit"
                  size="small"
                >
                  编辑
                </el-button>
                <el-button
                  @click="deleteItem(scope.row)"
                  v-if="scope.row.is_admin != 1 && scope.row.admin_type != 2"
                  class="btn"
                  type="text"
                  icon="el-icon-delete"
                  size="small"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="page_pagination">
            <el-pagination
              background
              layout="total,prev,pager,next,jumper"
              @current-change="handleCurrentChange"
              :total="pageConfig.counts"
              :page-size="pageConfig.pageSize"
              :current-page="pageConfig.pageIndex"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="新增管理员"
      :visible.sync="dialogFormVisible"
      width="400px"
      :wrapperClosable="false"
      :close-on-press-escape="false"
    >
      <el-form
        ref="userForm"
        :rules="userRules"
        :model="userForm"
        label-width="100px"
        size="small"
      >
        <el-form-item label="姓名：" size="small" prop="name">
          <el-input
            v-model="userForm.name"
            placeholder="姓名"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户名：" size="small" prop="username">
          <el-input
            v-model="userForm.username"
            placeholder="用户名"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="权限组：" size="small" prop="user_group_id">
          <el-select
            v-model="userForm.user_group_id"
            size="small"
            popper-class="select"
            style="width: 185px"
          >
            <el-option
              v-for="item in groupList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="密码：" size="small" prop="password">
          <el-input
            v-model="userForm.password"
            placeholder="密码"
            type="password"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码：" size="small" prop="com_password">
          <el-input
            v-model="userForm.com_password"
            type="password"
            placeholder="确认密码"
            size="small"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" style="text-align: center">
        <el-button
          type="primary"
          native-type="submit"
          @click.native.prevent="handleSubmit"
          :loading="btnLogining"
          size="small"
          >确 定</el-button
        >
        <el-button @click="dialogFormVisible = false" size="small"
          >取 消</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="编辑管理员"
      :visible.sync="dialogEditFormVisible"
      width="400px"
      :wrapperClosable="false"
      :close-on-press-escape="false"
    >
      <el-form
        ref="userEditForm"
        :rules="userEditRules"
        :model="userEditForm"
        label-width="100px"
        size="small"
      >
        <el-form-item label="姓名：" size="small" prop="name">
          <el-input
            v-model="userEditForm.name"
            placeholder="姓名"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="权限组：" size="small" prop="user_group_id">
          <el-select
            v-model="userEditForm.user_group_id"
            size="small"
            popper-class="select"
            style="width: 170px"
          >
            <el-option
              v-for="item in groupList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" style="text-align: center">
        <el-button
          type="primary"
          native-type="submit"
          @click.native.prevent="handleEditSubmit"
          :loading="btnLogining"
          size="small"
          >确 定</el-button
        >
        <el-button @click="dialogEditFormVisible = false" size="small"
          >取 消</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="重置密码"
      :visible.sync="dialogPasswordFormVisible"
      width="400px"
      :wrapperClosable="false"
      :close-on-press-escape="false"
    >
      <el-form
        ref="userPasswordForm"
        :rules="userPasswordRules"
        :model="userPasswordForm"
        label-width="100px"
        size="small"
      >
        <el-form-item label="姓名：" size="small">
          {{ userPasswordForm.name }}
        </el-form-item>
        <el-form-item label="用户名：" size="small">
          {{ userPasswordForm.username }}
        </el-form-item>
        <el-form-item label="密码：" size="small" prop="password">
          <el-input
            v-model="userPasswordForm.password"
            placeholder="密码"
            type="password"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码：" size="small" prop="com_password">
          <el-input
            v-model="userPasswordForm.com_password"
            type="password"
            placeholder="确认密码"
            size="small"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" style="text-align: center">
        <el-button
          type="primary"
          native-type="submit"
          @click.native.prevent="handlePasswordSubmit"
          :loading="btnLogining"
          size="small"
          >确 定</el-button
        >
        <el-button @click="dialogPasswordFormVisible = false" size="small"
          >取 消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {onTableHeight} from "@/lib/table";
import userApi from "@/api/user";
export default {
  data() {
    return {
      loading: false,
      btnLogining: false,
      dialogFormVisible: false,
      dialogEditFormVisible: false,
      dialogPasswordFormVisible: false,
      searchForm: {
        key: "",
      },
      statusOptions: [
        {
          status: 1,
          name: "开通",
        },
        {
          status: 0,
          name: "禁用",
        },
      ],
      formTitle: "",
      userID: 0,
      userForm: {
        name: "",
        username: "",
        user_group_id: "",
        password: "",
        com_password: "",
      },
      userRules: {
        name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          { min: 1, max: 100, message: "长度不超过100个字符" },
        ],
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { min: 4, max: 20, message: "长度为4至20个字符" },
        ],
        user_group_id: [{ required: true, message: "请选择权限组" }],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 4, max: 30, message: "长度为4至30个字符" },
        ],
        com_password: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          { min: 4, max: 30, message: "长度为4至30个字符" },
        ],
      },
      userEditForm: {
        name: "",
        user_group_id: "",
      },
      userEditRules: {
        name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          { min: 1, max: 100, message: "长度不超过100个字符" },
        ],
        user_group_id: [{ required: true, message: "请选择权限组" }],
      },
      userPasswordForm: {
        name: "",
        username: "",
        password: "",
        com_password: "",
      },
      userPasswordRules: {
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 4, max: 30, message: "长度为4至30个字符" },
        ],
        com_password: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          { min: 4, max: 30, message: "长度为4至30个字符" },
        ],
      },
      groupList: [],
      listData: [],
      pageConfig: { pageSize: 50, pageIndex: 1, counts: 0 },
      tableHeight: 500,
    };
  },
  mounted() {
    onTableHeight(this);
    this.getList(true);
  },
  methods: {
    //查询
    handleSearch() {
      this.getList(true);
    },
    resetSearchForm(formName) {
      this.searchForm = {
        key: "",
      };
      this.getList(true);
    },
    //表单
    doForm() {
      this.userForm = {
        name: "",
        username: "",
        user_group_id: "",
        password: "",
        com_password: "",
      };
      let message = this.$message({
        message: "加载中",
        iconClass: "el-icon-loading",
        customClass: "message-loading",
        duration: 0,
        offset: 60,
      });
      userApi
        .groupList(this)
        .then((res) => {
          this.groupList = res.list;
          this.dialogFormVisible = true;
          message.close();
          if (this.$refs["userForm"]) this.$refs["userForm"].resetFields();
        })
        .catch((res) => {
          message.close();
        });
    },
    //分页
    handleCurrentChange(val) {
      this.getList(true, val);
    },
    //列表
    getList(loading, pageIndex = 1) {
      this.loading = loading;
      userApi
        .userList({
          key: this.searchForm.key,
          page_size: this.pageConfig.pageSize,
          page_index: pageIndex,
        })
        .then((res) => {
          this.loading = false;
          this.listData = res.list;
          this.pageConfig.pageIndex = res.page.page_index;
          this.pageConfig.counts = res.page.counts;
        })
        .catch((res) => {
          this.loading = false;
        });
    },
    //管理员状态
    setStatus(row) {
      let message = this.$message({
        message: "请求中",
        iconClass: "el-icon-loading",
        customClass: "message-loading",
        duration: 0,
        offset: 60,
      });
      userApi
        .userStatus({
          id: row.id,
          status: row.status,
        })
        .then((res) => {
          message.close();
          this.$message({
            message: "设置成功",
            type: "success",
            offset: 60,
          });
          this.getList(false, this.pageConfig.pageIndex);
        })
        .catch((res) => {
          message.close();
        });
    },
    //删除管理员
    deleteItem(row) {
      this.$confirm("确定是否删除该管理员?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let message = this.$message({
            message: "请求中",
            iconClass: "el-icon-loading",
            customClass: "message-loading",
            duration: 0,
            offset: 60,
          });
          userApi
            .userDelete({
              id: row.id,
            })
            .then((res) => {
              message.close();
              this.$message({
                message: "删除成功",
                type: "success",
                offset: 60,
              });
              this.getList(true, this.pageConfig.pageIndex);
            })
            .catch((res) => {
              message.close();
            });
        })
        .catch(() => {});
    },
    handleSubmit() {
      this.$refs.userForm.validate((valid) => {
        if (valid) {
          if (this.userForm.password != this.userForm.com_password) {
            this.$message({
              message: "两次密码输入不一致",
              type: "error",
              offset: 60,
            });
            return;
          }
          this.btnLogining = true;

          userApi
            .userForm({
              name: this.userForm.name,
              username: this.userForm.username,
              user_group_id: this.userForm.user_group_id,
              password: this.userForm.password,
              com_password: this.userForm.com_password,
            })
            .then((res) => {
              this.btnLogining = false;
              this.$message({
                message: "提交成功",
                type: "success",
                offset: 60,
              });
              this.dialogFormVisible = false;
              this.getList(true, this.pageConfig.pageIndex);
            })
            .catch((res) => {
              this.btnLogining = false;
            });
        } else {
          return false;
        }
      });
    },
    editItem(row) {
      this.userID = row.id;
      console.log(row);
      this.userEditForm = {
        name: row.name,
        user_group_id: row.user_group_id,
      };
      let message = this.$message({
        message: "加载中",
        iconClass: "el-icon-loading",
        customClass: "message-loading",
        duration: 0,
        offset: 60,
      });
      userApi
        .getGroupList(this)
        .then((res) => {
          this.groupList = res.list;
          this.dialogEditFormVisible = true;
          message.close();
          if (this.$refs["userEditForm"])
            this.$refs["userEditForm"].resetFields();
        })
        .catch((res) => {
          message.close();
        });
    },
    handleEditSubmit() {
      this.$refs.userEditForm.validate((valid) => {
        if (valid) {
          this.btnLogining = true;

          userApi
            .userEdit({
              id: this.userID,
              name: this.userEditForm.name,
              user_group_id: this.userEditForm.user_group_id,
            })
            .then((res) => {
              this.btnLogining = false;
              this.$message({
                message: "提交成功",
                type: "success",
                offset: 60,
              });
              this.dialogEditFormVisible = false;
              this.getList(true, this.pageConfig.pageIndex);
            })
            .catch((res) => {
              this.btnLogining = false;
            });
        } else {
          return false;
        }
      });
    },
    passwordItem(row) {
      this.userID = row.id;
      this.userPasswordForm = {
        name: row.name,
        username: row.username,
        password: "",
        com_password: "",
      };
      this.dialogPasswordFormVisible = true;
      if (this.$refs["userPasswordForm"])
        this.$refs["userPasswordForm"].resetFields();
    },
    handlePasswordSubmit() {
      this.$refs.userPasswordForm.validate((valid) => {
        if (valid) {
          this.btnLogining = true;
          userApi
            .userResetPassword({
              id: this.userID,
              password: this.userPasswordForm.password,
              com_password: this.userPasswordForm.com_password,
            })
            .then((res) => {
              this.btnLogining = false;
              this.$message({
                message: "提交成功",
                type: "success",
                offset: 60,
              });
              this.dialogPasswordFormVisible = false;
            })
            .catch((res) => {
              this.btnLogining = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scope>
</style>
